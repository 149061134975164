const data = {
  "/config": {
    blocks: [
      {
        kind: "welcome",
        props: {
          title: "Bdash",
        },
        background_color: "darkslategray",
        color: "white",
        width: 12,
        height: 1,
        static: true,
      },
      {
        kind: "titleSubtitle",
        props: {
          title: "Health",
          subtitle: "-1",
        },
        background_color: "palevioletred",
        color: "white",
        path_url: "/health",
        width: 2,
        height: 1,
        x: 0,
        y: 1,
        static: false,
      },
      {
        kind: "titleNumberIcon",
        props: {
          title: "Network Usage",
          value: "-1",
          icon: "🖧",
        },
        background_color: "#1b263b",
        color: "white",
        path_url: "/network-usage",
        width: 2,
        height: 1,
        x: 2,
        y: 1,
        static: false,
      },
      {
        kind: "titleSubtitle",
        props: {
          title: "Data Backend",
          subtitle: "-1",
        },
        background_color: "coral",
        color: "white",
        path_url: "/backend",
        width: 2,
        height: 1,
        x: 4,
        y: 1,
        static: false,
      },
      {
        kind: "titleNumberIcon",
        props: {
          title: "Status",
          value: "-1",
          icon: "⦿",
          object: true,
        },
        background_color: "salmon",
        color: "white",
        path_url: "/status",
        width: 2,
        height: 1,
        x: 8,
        y: 1,
        static: false,
      },
      {
        kind: "titleNumberIcon",
        props: {
          title: "Weather",
          value: "-1",
          icon: "☀️",
        },
        background_color: "cadetblue",
        color: "white",
        path_url: "/weather",
        width: 2,
        height: 1,
        x: 6,
        y: 1,
        static: false,
      },
      {
        kind: "titleSubtitle",
        props: {
          title: "Person on Call",
          subtitle: "-1",
          object: true,
        },
        background_color: "cornflowerblue",
        color: "white",
        path_url: "/oncall",
        width: 2,
        height: 1,
        x: 10,
        y: 1,
        static: false,
      },
    ],
  },
  "/network-usage": "5400 bytes",
  "/health": "OK",
  "/weather": "Sunny",
  "/backend": "SessionStorage",
  "/status": { number: "Sane", ts: "2023-03-10T00:00:00.000Z" },
  "/oncall": { subtitle: "John Doe", ts: "2023-02-21T10:00:00.000Z" },
};

export default data;
